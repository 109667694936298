<template>
  <div class="view">
    <Topbar :seccion="'eventos'"></Topbar>
    <div class="view-container" ref="eventosContainer" @scroll="onScroll">
      <div class="secciones-top">
        <label class="secciones-title">Eventos</label>
        <Dropdown
          class="dropdown"
          :options="vigilancias"
          :placeholder="'Todas las vigilancias'"
          :paraArriba="false"
          :conInput="false"
          :name="'nombre'"
          v-on:updateOption="updateDropdownVigilancia"
        ></Dropdown>
        <div
          class="secciones-buscar"
          :class="{ 'secciones-buscar-active': searchText != '' }"
        >
          <input
            type="text"
            placeholder="Buscar"
            v-model="searchText"
            @keyup="filtrar()"
          />
          <img
            class="secciones-buscar-close"
            src="../assets/img/icon/close.png"
            @click="resetBuscar()"
          />
          <img class="secciones-buscar-img" src="../assets/img/icon/lupa.png" />
        </div>
        <DesdeHasta @desde="setDesde" @hasta="setHasta"></DesdeHasta>
        <button
          class="button-secondary secciones-descargar-btn"
          @click="mostrarDescarga = true"
        >
          <img src="../assets/img/icon/descarga.png" /> Descargar Eventos
        </button>
      </div>
      <div class="eventos-header">
        <div @click="elegirOpcion('fecha')">
          <label class="eventos-header-label">Fecha</label>
          <img
            v-show="opcion == 'fecha' || opcion == 'fecha-desc'"
            class="eventos-header-img"
            :class="{ 'eventos-header-img-active': opcion == 'fecha-desc' }"
            src="../assets/img/icon/triangulo.png"
          />
        </div>
        <div @click="elegirOpcion('empresa')">
          <label class="eventos-header-label">Empresa</label>
          <img
            v-show="opcion == 'empresa' || opcion == 'empresa-desc'"
            class="eventos-header-img"
            :class="{ 'eventos-header-img-active': opcion == 'empresa-desc' }"
            src="../assets/img/icon/triangulo.png"
          />
        </div>
        <div @click="elegirOpcion('vigilancia')">
          <label class="eventos-header-label">Vigilancia</label>
          <img
            v-show="opcion == 'vigilancia' || opcion == 'vigilancia-desc'"
            class="eventos-header-img"
            :class="{
              'eventos-header-img-active': opcion == 'vigilancia-desc',
            }"
            src="../assets/img/icon/triangulo.png"
          />
        </div>
        <div @click="elegirOpcion('tipo')">
          <label class="eventos-header-label">Tipo</label>
          <img
            v-show="opcion == 'tipo' || opcion == 'tipo-desc'"
            class="eventos-header-img"
            :class="{ 'eventos-header-img-active': opcion == 'tipo-desc' }"
            src="../assets/img/icon/triangulo.png"
          />
        </div>
        <div @click="elegirOpcion('titulo')">
          <label class="eventos-header-label">Título</label>
          <img
            v-show="opcion == 'titulo' || opcion == 'titulo-desc'"
            class="eventos-header-img"
            :class="{ 'eventos-header-img-active': opcion == 'titulo-desc' }"
            src="../assets/img/icon/triangulo.png"
          />
        </div>
        <div @click="elegirOpcion('descripcion')">
          <label class="eventos-header-label">Descripción</label>
          <img
            v-show="opcion == 'descripcion' || opcion == 'descripcion-desc'"
            class="eventos-header-img"
            :class="{
              'eventos-header-img-active': opcion == 'descripcion-desc',
            }"
            src="../assets/img/icon/triangulo.png"
          />
        </div>
      </div>
      <div class="secciones-eventos">
        <div
          v-for="evento in eventosFiltrados"
          :key="evento.id"
          class="eventos-evento"
          @click="elegirEvento(evento)"
        >
          <label class="evento-label">{{
            getFecha(evento.horario_suceso)
          }}</label>
          <label class="evento-label">{{
            evento.vigilancia != null && evento.vigilancia.empresa != null
              ? evento.vigilancia.empresa.nombre
              : "-"
          }}</label>
          <label class="evento-label">{{
            evento.vigilancia != null
              ? evento.vigilancia.nombre
              : evento.ubicacion_alternativa != null
              ? "En vía pública"
              : "-"
          }}</label>
          <label class="evento-label evento-label-capitalize">{{
            evento.tipo != "incidente" ? evento.tipo : "Reporte de Incidente"
          }}</label>
          <label class="evento-label">{{ evento.titulo }}</label>
          <label class="evento-label">{{ evento.descripcion }}</label>
        </div>
      </div>
      <button
        class="button button-primary secciones-crear-btn"
        @click="mostrarNuevo = true"
      >
        <img src="../assets/img/icon/eventos_white.png" />Crear nuevo evento
      </button>
    </div>
    <Nuevo
      v-show="mostrarNuevo"
      @cerrar="cerrarNuevo()"
      @get="getEventos()"
    ></Nuevo>
    <Info
      v-if="mostrarInfo"
      :evento="eventoElegido"
      @cerrar="cerrarInfo()"
      @editar="editar"
      @get="getEventos()"
    ></Info>
    <Descarga v-show="mostrarDescarga" @cerrar="cerrarDescarga()"></Descarga>
  </div>
</template>

<script>
import Topbar from "@/components/Topbar.vue";
import Nuevo from "@/components/eventos/Nuevo.vue";
import Info from "@/components/eventos/Info.vue";
import Dropdown from "@/components/Dropdown.vue";
import DesdeHasta from "@/components/DesdeHasta.vue";
import Descarga from "@/components/eventos/Descarga.vue";
import { ordenarJson } from "@/assets/js/general.js";

export default {
  name: "Eventos",
  components: { Topbar, Nuevo, Info, Dropdown, DesdeHasta, Descarga },
  data() {
    return {
      searchText: "",
      eventos: [],
      eventosFiltrados: [],
      vigilancias: [],
      meses: [
        "Ene",
        "Feb",
        "Mar",
        "Abr",
        "May",
        "Jun",
        "Jul",
        "Ago",
        "Sep",
        "Oct",
        "Nov",
        "Dic",
      ],
      mostrarNuevo: false,
      mostrarInfo: false,
      eventoElegido: null,
      opcion: null,
      mostrarDescarga: false,
      filtroDesde: null,
      filtroHasta: null,
      filtroVigilancia: null,
      pageNumber: 0,
      getting: false,
    };
  },
  mounted() {
    this.getEventos();
    this.getVigilancias();
  },
  methods: {
    ordenarJson: function (json, args, asc) {
      return ordenarJson(json, args, asc);
    },
    updateDropdownVigilancia(payload) {
      this.pageNumber = 0;
      this.eventos = [];
      if (payload.nombre == "Todas") {
        this.filtroVigilancia = null;
      } else {
        this.filtroVigilancia = payload.id;
      }
      this.getEventosByVigilancia();
    },
    cerrarNuevo() {
      this.mostrarNuevo = false;
    },
    cerrarInfo() {
      this.mostrarInfo = false;
    },
    elegirEvento(evento) {
      this.eventoElegido = evento;
      this.mostrarInfo = true;
    },
    setDesde(desde) {
      this.filtroDesde = desde;
      this.filtrar();
    },
    setHasta(hasta) {
      this.filtroHasta = hasta;
      this.filtrar();
    },
    resetBuscar() {
      this.searchText = "";
      this.filtrar();
    },
    filtrar() {
      let filtroBuscar = this.searchText
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase();

      this.eventosFiltrados = this.eventos.filter(
        (e) =>
          (this.filtroVigilancia == null ||
            (e.vigilancia != null &&
              e.vigilancia.id == this.filtroVigilancia) ||
            (e.vigilancia == null &&
              e.ubicacion_alternativa != null &&
              this.filtroVigilancia == true)) &&
          (this.searchText == "" ||
            (e.vigilancia != null &&
              e.vigilancia.nombre.toLowerCase().indexOf(filtroBuscar) > -1) ||
            (e.vigilancia != null &&
              e.vigilancia.empresa != null &&
              e.vigilancia.empresa.nombre.toLowerCase().indexOf(filtroBuscar) >
                -1) ||
            (e.tipo != null &&
              e.tipo.toLowerCase().indexOf(filtroBuscar) > -1) ||
            (e.titulo != null &&
              e.titulo.toLowerCase().indexOf(filtroBuscar) > -1) ||
            (e.vigilancia == null &&
              e.ubicacion_alternativa != null &&
              "En via publica".indexOf(filtroBuscar) > -1)) &&
          (this.filtroDesde == null || e.horario_suceso >= this.filtroDesde) &&
          (this.filtroHasta == null ||
            e.horario_suceso <= this.filtroHasta + "T23:59:59")
      );
    },
    editar(evento) {
      this.mostrarNuevo = true;
      this.$emitter.emit("eventos-editar", evento);
    },
    getEventos() {
      if (!this.getting) {
        if (this.filtroVigilancia) {
          this.getEventosByVigilancia();
        } else {
          this.getEventosAll();
        }
      }
    },
    getEventosAll() {
      var that = this;
      this.getting = true;
      this.$axios
        .get(this.$serverURL + "/api/eventos/page/" + this.pageNumber + "/")
        .then(function (response) {
          if (response.data.length > 0) {
            that.pageNumber = that.pageNumber + 5;
            that.eventos = that.eventos.concat(response.data);
            that.eventosFiltrados = that.eventos;
            that.$nextTick(() => {
              if (
                that.$refs.eventosContainer.scrollHeight ==
                that.$refs.eventosContainer.clientHeight
              ) {
                that.getEventosAll();
              }
            });
          }
        })
        .catch(function (response) {
          console.log(response);
        })
        .finally(() => (this.getting = false));
    },
    getEventosByVigilancia() {
      var that = this;
      this.getting = true;
      this.$axios
        .get(
          this.$serverURL +
            "/api/eventos/all/filter/" +
            this.filtroVigilancia +
            "/page/" +
            this.pageNumber +
            "/"
        )
        .then(function (response) {
          if (response.data.length > 0) {
            that.pageNumber = that.pageNumber + 5;
            that.eventos = that.eventos.concat(response.data);
            that.eventosFiltrados = that.eventos;
            that.$nextTick(() => {
              if (
                that.$refs.eventosContainer.scrollHeight ==
                that.$refs.eventosContainer.clientHeight
              ) {
                that.getEventosByVigilancia();
              }
            });
          }
        })
        .catch(function (response) {
          console.log(response);
        })
        .finally(() => (this.getting = false));
    },
    getVigilancias() {
      var that = this;
      this.$axios
        .get(this.$serverURL + "/api/vigilancias/all/")
        .then(function (response) {
          console.log("vigilancias", response);
          that.vigilancias = response.data;
          that.vigilancias.push({ id: true, nombre: "En vía pública" });
          that.vigilancias.push({ nombre: "Todas" });
        })
        .catch(function (response) {
          console.log(response);
        });
    },
    elegirOpcion(opcion) {
      let asc = true;
      if (this.opcion == opcion) {
        this.opcion = opcion + "-desc";
        asc = false;
      } else {
        this.opcion = opcion;
      }
      switch (opcion) {
        case "empresa":
          this.eventos = this.ordenarJson(
            this.eventos,
            ["turno", "vigilancia", "empresa", "nombre"],
            asc
          );
          break;
        case "vigilancia":
          this.eventos = this.ordenarJson(
            this.eventos,
            ["turno", "vigilancia", "nombre"],
            asc
          );
          break;
        default:
          this.eventos = this.ordenarJson(this.eventos, [opcion], asc);
          break;
      }
    },
    getFecha(fecha) {
      let d = new Date(fecha);
      return (
        d.getDate() + " " + this.meses[d.getMonth()] + ", " + d.getFullYear()
      );
    },
    cerrarDescarga() {
      this.mostrarDescarga = false;
    },
    onScroll() {
      var target = this.$refs.eventosContainer;
      if (
        target.scrollTop != 0 &&
        target.scrollTop + target.clientHeight >= target.scrollHeight - 10
      ) {
        this.getEventos();
      }
    },
  },
};
</script>

<style scoped lang="scss" src="@/assets/css/views/secciones.scss"></style>
<style scoped lang="scss" src="@/assets/css/views/eventos.scss"></style>
