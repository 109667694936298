<template>
  <div class="popup-container">
    <div class="popup-mask"></div>
    <div class="popup">
      <div class="popup-top">
        <label>Descarga</label>
        <img src="../../assets/img/icon/close_popup.png" @click="cerrar()" />
      </div>
      <div class="popup-body">
        <div class="popup-row">
          <label>Empresa</label>
          <Dropdown
            class="select"
            :options="empresas"
            :placeholder="'Todas las empresas'"
            :paraArriba="false"
            :conInput="false"
            :name="'nombre'"
            ref="dropdownEmpresa"
            v-on:updateOption="updateDropdownEmpresa"
            :class="{ 'error-dropdown': errorEmpresa }"
          ></Dropdown>
        </div>
        <div class="popup-row">
          <label>Vigilancia</label>
          <Dropdown
            class="select"
            :options="vigilancias"
            :placeholder="'Todas las vigilancias'"
            :paraArriba="false"
            :conInput="false"
            :name="'nombre'"
            v-on:updateOption="updateDropdownVigilancia"
            :class="{ 'error-dropdown': errorVigilancia }"
          ></Dropdown>
        </div>
        <div class="popup-row">
          <label>Desde</label>
          <div class="popup-dates">
            <input
              class="popup-date"
              type="text"
              placeholder="DD"
              maxlength="2"
              id="desde-input-1"
              v-model="desdeDay"
              @keypress="onlyNumbersPress"
              @keyup="onlyNumbers($event, 2)"
              :class="{ 'error-dropdown': errorDesde }"
            />
            <input
              class="popup-date"
              type="text"
              placeholder="MM"
              maxlength="2"
              id="desde-input-2"
              v-model="desdeMonth"
              @keypress="onlyNumbersPress"
              @keyup="onlyNumbers($event, 2)"
              :class="{ 'error-dropdown': errorDesde }"
            />
            <input
              class="popup-date"
              type="text"
              placeholder="AAAA"
              maxlength="4"
              id="desde-input-3"
              v-model="desdeYear"
              @keypress="onlyNumbersPress"
              @keyup="onlyNumbers($event, 4)"
              :class="{ 'error-dropdown': errorDesde }"
            />
          </div>
        </div>
        <div class="popup-row">
          <label>Hasta</label>
          <div class="popup-dates">
            <input
              class="popup-date"
              type="text"
              placeholder="DD"
              maxlength="2"
              id="hasta-input-1"
              v-model="hastaDay"
              @keypress="onlyNumbersPress"
              @keyup="onlyNumbers($event, 2)"
              :class="{ 'error-dropdown': errorHasta }"
            />
            <input
              class="popup-date"
              type="text"
              placeholder="MM"
              maxlength="2"
              id="hasta-input-2"
              v-model="hastaMonth"
              @keypress="onlyNumbersPress"
              @keyup="onlyNumbers($event, 2)"
              :class="{ 'error-dropdown': errorHasta }"
            />
            <input
              class="popup-date"
              type="text"
              placeholder="AAAA"
              maxlength="4"
              id="hasta-input-3"
              v-model="hastaYear"
              @keypress="onlyNumbersPress"
              @keyup="onlyNumbers($event, 4)"
              :class="{ 'error-dropdown': errorHasta }"
            />
          </div>
        </div>
      </div>
      <div class="popup-bot-border">
        <button
          v-show="!guardando"
          class="button button-primary popup-btn-continuar-chico"
          @click="finalizar()"
        >
          Finalizar
        </button>
        <button
          v-show="guardando"
          class="button button-ok popup-btn-continuar-chico"
        >
          Finalizando
        </button>
      </div>
    </div>
    <Salir
      :mostrar="mostrarSalir"
      :tipo="'descarga'"
      @cerrar="mostrarSalir = false"
      @aceptar="cerrar()"
    ></Salir>
  </div>
</template>

<script>
import Salir from "@/components/Salir.vue";
import Dropdown from "@/components/Dropdown.vue";

export default {
  name: "Descarga_Eventos",
  components: { Dropdown, Salir },
  data() {
    return {
      id: null,
      empresas: [],
      vigilancias: [],
      empresa: null,
      vigilancia: null,
      desdeDay: "",
      desdeMonth: "",
      desdeYear: "",
      hastaDay: "",
      hastaMonth: "",
      hastaYear: "",
      errorEmpresa: false,
      errorVigilancia: false,
      errorDesde: false,
      errorHasta: false,
      guardando: false,
      mostrarSalir: false,
    };
  },
  mounted() {
    this.getEmpresas();
    this.getVigilancias();
  },
  methods: {
    updateDropdownEmpresa(payload) {
      if (payload.nombre != "Todas") {
        this.empresa = payload;
      } else {
        this.empresa = null;
      }
    },
    updateDropdownVigilancia(payload) {
      if (payload.nombre != "Todas") {
        this.vigilancia = payload;
      } else {
        this.vigilancia = null;
      }
    },
    onlyNumbers($event, max) {
      var key = $event.currentTarget.value.substr(
        $event.currentTarget.value.length - 1,
        1
      );
      if (
        key != "0" &&
        key != "1" &&
        key != "2" &&
        key != "3" &&
        key != "4" &&
        key != "5" &&
        key != "6" &&
        key != "7" &&
        key != "8" &&
        key != "9"
      ) {
        $event.currentTarget.value = $event.currentTarget.value.substr(
          0,
          $event.currentTarget.value.length - 1
        );
      } else {
        this.checkFocus($event, max);
      }
    },
    onlyNumbersPress($event) {
      var key = $event.key;
      if (
        key != "0" &&
        key != "1" &&
        key != "2" &&
        key != "3" &&
        key != "4" &&
        key != "5" &&
        key != "6" &&
        key != "7" &&
        key != "8" &&
        key != "9"
      ) {
        $event.preventDefault();
      }
    },
    checkFocus($event, max) {
      if ($event.target.value.length == max) {
        let id = $event.target.id.substr(0, $event.target.id.length - 1);
        let number =
          parseFloat(
            $event.target.id.substr(
              $event.target.id.length - 1,
              $event.target.id.length
            )
          ) + 1;
        try {
          const elemento = document.getElementById(id + number);
          if (elemento != null) {
            elemento.focus();
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
    getEmpresas() {
      var that = this;
      this.$axios
        .get(this.$serverURL + "/api/empresas/all/")
        .then(function (response) {
          that.empresas = response.data;
          that.empresas.push({ nombre: "Todas" });
        })
        .catch(function (response) {
          console.log(response);
        });
    },
    getVigilancias() {
      var that = this;
      this.$axios
        .get(this.$serverURL + "/api/vigilancias/all/")
        .then(function (response) {
          that.vigilancias = response.data;
          that.vigilancias.push({ nombre: "Todas" });
        })
        .catch(function (response) {
          console.log(response);
        });
    },
    finalizar() {
      this.guardando = true;
      let continuar = true;

      if (!this.isValidDate(this.desdeYear, this.desdeMonth, this.desdeDay)) {
        this.errorDesde = true;
        continuar = false;
      } else {
        this.errorDesde = false;
      }

      if (!this.isValidDate(this.hastaYear, this.hastaMonth, this.hastaDay)) {
        this.errorHasta = true;
        continuar = false;
      } else {
        this.errorHasta = false;
      }

      if (continuar) {
        console.log("descargar eventos");
        let json = {
          fecha_inicio:
            this.desdeYear +
            "-" +
            this.desdeMonth +
            "-" +
            this.desdeDay +
            "T00:00:00",
          fecha_fin:
            this.hastaYear +
            "-" +
            this.hastaMonth +
            "-" +
            this.hastaDay +
            "T23:59:59",
          empresa: this.empresa != null ? this.empresa.id : "-1",
          vigilancia: this.vigilancia != null ? this.vigilancia.id : "-1",
        };
        var that = this;
        this.$axios
          .post(this.$serverURL + "/api/eventos/excel", json)
          .then(function (response) {
            that.guardando = false;
            console.log(response);
            window
              .open(
                that.$serverURL + "/api/eventos/download/" + response.data.dir,
                "_blank"
              )
              .focus();
          })
          .catch(function (response) {
            that.guardando = false;
            console.log(response);
          });
      } else {
        this.guardando = false;
      }
    },
    isValidDate(year, month, day) {
      var d = new Date(year + "-" + month + "-" + day + " 21:00:00");
      if (
        d.getFullYear() == year &&
        d.getMonth() + 1 == month &&
        d.getDate() == day
      ) {
        return true;
      }
      return false;
    },
    cerrar() {
      this.$emit("cerrar");
      this.limpiar();
      this.mostrarSalir = false;
    },
    limpiar() {
      this.empresa = null;
      this.vigilancia = null;
      this.desdeDay = "";
      this.desdeMonth = "";
      this.desdeYear = "";
      this.hastaDay = "";
      this.hastaMonth = "";
      this.hastaYear = "";
    },
  },
};
</script>

<style scoped lang="scss" src="@/assets/css/views/secciones.scss">
</style>
