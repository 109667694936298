function ordenarJson(json, args, asc) {
  var result = json.sort(function (a, b) {
    var aProp = getProp(a, args) != "-" ? getProp(a, args) : null;
    var bProp = getProp(b, args) != "-" ? getProp(b, args) : null;
    if (asc) {
      return aProp > bProp ? 1 : aProp < bProp ? -1 : 0;
    }
    return bProp > aProp ? 1 : bProp < aProp ? -1 : 0;
  });
  return result;
}
function ordenarJsonAlfanumeric(json, prop, asc) {
  var result = json.sort(function (a, b) {
    var aProp = a[prop] != "-" ? a[prop] : null;
    var bProp = b[prop] != "-" ? b[prop] : null;
    if (asc) {
      return aProp.localeCompare(bProp, "en", { numeric: true });
    }
    return bProp.localeCompare(aProp, "en", { numeric: true });
  });
  return result;
}
function getProp(json, array) {
  if (array.length == 1) {
    return json[array[0]];
  } else {
    return getProp(json[array[0]], array.slice(1, array.length));
  }
}

export { ordenarJson, ordenarJsonAlfanumeric };
