<template>
  <div class="popup-container">
    <div class="popup-mask"></div>
    <div class="popup popup-grande">
      <div class="popup-top">
        <label>{{ titulo }}</label>
        <img
          src="../../assets/img/icon/close_popup.png"
          @click="mostrarSalir = true"
        />
      </div>
      <div class="popup-body">
        <div class="popup-row-double">
          <div>
            <div class="popup-row">
              <label>Vigilancia (*)</label>
              <label class="checkbox-container">
                <label @click="direccionAlternaCheck = !direccionAlternaCheck"
                  >Dirección alterna</label
                >
                <input type="checkbox" v-model="direccionAlternaCheck" />
                <span class="checkbox-checkmark"></span>
              </label>
              <Dropdown
                v-show="!direccionAlternaCheck"
                class="select"
                :options="vigilancias"
                :placeholder="'Seleccione vigilancia'"
                :paraArriba="false"
                :conInput="false"
                :name="'nombre'"
                ref="dropdownVigilancia"
                v-on:updateOption="updateDropdownVigilancia"
                :class="{ 'error-dropdown': errorVigilancia }"
              ></Dropdown>
              <div
                v-show="direccionAlternaCheck"
                class="input input-primary"
                :class="{ 'input-error': errorDireccionAlterna }"
              >
                <input
                  type="text"
                  placeholder="Escribe dirección del suceso"
                  v-model="direccionAlterna"
                />
              </div>
            </div>
            <div class="popup-row">
              <label>Tipo de evento (*)</label>
              <Dropdown
                class="select"
                :options="tipos"
                :placeholder="'Seleccione tipo'"
                :paraArriba="false"
                :conInput="false"
                :name="'name'"
                ref="dropdownTipo"
                v-on:updateOption="updateDropdownTipo"
                :class="{ 'error-dropdown': errorTipo }"
              ></Dropdown>
            </div>
            <div class="popup-row">
              <label>Título del evento (*)</label>
              <div
                class="input input-primary"
                :class="{ 'input-error': errorTitulo }"
              >
                <input
                  type="text"
                  placeholder="Escribe título de la novedad"
                  v-model="tituloEvento"
                />
              </div>
            </div>
            <div class="popup-row-double">
              <div class="popup-row">
                <label>Horario de suceso (*)</label>
                <Timepicker
                  class="nuevaRutina-timepicker"
                  ref="timepicker"
                  :value="horario"
                  :minute-interval="1"
                  :abrir="true"
                  @change="changeTime"
                  :hourRange="getRangoHoraHasta()"
                  :class="{ 'input-error': errorHorario }"
                ></Timepicker>
              </div>
              <div class="popup-row">
                <label>Imagen</label>
                <div class="popup-adjuntar">
                  <label @click="elegirImagen()" v-show="imagenNombre == ''"
                    >Adjuntar imagen</label
                  >
                  <label @click="elegirImagen()" v-show="imagenNombre != ''">{{
                    imagenNombre
                  }}</label>
                  <input
                    class="app-hide"
                    type="file"
                    ref="imagenUpload"
                    @change="changeImagen()"
                    accept="image/png, image/jpeg"
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="popup-row popup-row-max-height">
              <label>Descripción del evento (*)</label>
              <div
                class="input input-primary"
                :class="{ 'input-error': errorDescripcion }"
              >
                <textarea
                  type="text"
                  placeholder="Escribe una descripción de la novedad"
                  v-model="descripcion"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="popup-bot-border">
        <button
          v-show="!guardando"
          class="button button-primary popup-btn-continuar-chico"
          @click="finalizar()"
        >
          Finalizar
        </button>
        <button
          v-show="guardando"
          class="button button-ok popup-btn-continuar-chico"
        >
          Finalizando
        </button>
      </div>
    </div>
    <Salir
      :mostrar="mostrarSalir"
      :tipo="titulo.toLowerCase()"
      @cerrar="mostrarSalir = false"
      @aceptar="cerrar()"
    >
    </Salir>
  </div>
</template>

<script>
import Salir from "@/components/Salir.vue";
import Dropdown from "@/components/Dropdown.vue";
import Timepicker from "@/components/Timepicker.vue";

export default {
  name: "Nuevo_Evento",
  components: { Dropdown, Timepicker, Salir },
  data() {
    return {
      titulo: "Crear nuevo evento",
      id: null,
      tipos: [
        {
          name: "incidente",
        },
        {
          name: "novedad",
        },
      ],
      vigilancias: [],
      vigilancia: null,
      direccionAlterna: "",
      direccionAlternaCheck: false,
      tipo: "",
      tituloEvento: "",
      horario: null,
      descripcion: "",
      imagenNombre: "",
      errorTipo: false,
      errorVigilancia: false,
      errorDireccionAlterna: false,
      errorTitulo: false,
      errorHorario: false,
      errorDescripcion: false,
      guardando: false,
      mostrarSalir: false,
    };
  },
  mounted() {
    this.$emitter.on("eventos-editar", (email) => this.editar(email));
    this.getVigilancias();
  },
  beforeUnmount() {
    this.$emitter.off("eventos-editar");
  },
  methods: {
    editar(evento) {
      this.titulo = "Editar evento";
      this.id = evento.id;
      this.tipo = evento.tipo;
      this.$refs.dropdownTipo.selectedOption = this.tipos.find(
        (t) => t.name == evento.tipo
      );
      this.$refs.dropdownTipo.$refs.dropdownInput2.value = evento.tipo;
      this.tituloEvento = evento.titulo;
      let hora = evento.horario_suceso.split("T")[1];
      this.horario = {
        HH: hora.split(":")[0],
        mm: hora.split(":")[1],
      };
      this.imagen = null;
      this.imagenNombre = evento.imagen;
      this.descripcion = evento.descripcion;
      if (evento.vigilancia != null) {
        this.vigilancia = evento.vigilancia;
        this.$refs.dropdownVigilancia.selectedOption = evento.vigilancia;
        this.$refs.dropdownVigilancia.$refs.dropdownInput2.value =
          evento.vigilancia.nombre;
      }
      if (
        evento.ubicacion_alternativa != null &&
        evento.ubicacion_alternativa != ""
      ) {
        this.direccionAlternaCheck = true;
        this.direccionAlterna = evento.ubicacion_alternativa;
      }
    },
    updateDropdownTipo(payload) {
      this.tipo = payload.name;
    },
    updateDropdownVigilancia(payload) {
      this.vigilancia = payload;
    },
    getVigilancias() {
      var that = this;
      this.$axios
        .get(this.$serverURL + "/api/vigilancias/vinculadas/")
        .then(function (response) {
          that.vigilancias = response.data;
        })
        .catch(function (response) {
          console.log(response);
        });
    },
    finalizar() {
      this.guardando = true;
      let continuar = true;

      if (this.tipo == "") {
        this.errorTipo = true;
        continuar = false;
      } else {
        this.errorTipo = false;
      }

      if (this.direccionAlternaCheck == true) {
        this.vigilancia = null;
      } else {
        this.direccionAlterna = "";
      }

      if (this.vigilancia == null && this.direccionAlternaCheck == false) {
        this.errorVigilancia = true;
        continuar = false;
      } else {
        this.errorVigilancia = false;
      }

      if (this.direccionAlterna == "" && this.direccionAlternaCheck == true) {
        this.errorDireccionAlterna = true;
        continuar = false;
      } else {
        this.errorDireccionAlterna = false;
      }

      if (this.tituloEvento == "") {
        this.errorTitulo = true;
        continuar = false;
      } else {
        this.errorTitulo = false;
      }

      if (
        this.horario == null ||
        this.horario.HH == "" ||
        this.horario.mm == ""
      ) {
        this.errorHorario = true;
        continuar = false;
      } else {
        this.errorHorario = false;
      }

      if (this.descripcion == "") {
        this.errorDescripcion = true;
        continuar = false;
      } else {
        this.errorDescripcion = false;
      }

      if (
        this.horario.HH == new Date().getHours() &&
        this.horario.mm > new Date().getMinutes()
      ) {
        this.errorHorario = true;
        continuar = false;
      }

      if (continuar) {
        let json = {
          id: this.id,
          tipo: this.tipo,
          titulo: this.tituloEvento,
          horario_suceso: this.horario.HH + ":" + this.horario.mm,
          descripcion: this.descripcion,
          vigilancia: this.vigilancia != null ? this.vigilancia.id : null,
          ubicacion_alternativa: this.direccionAlterna,
        };
        if (this.imagen != null) {
          json.imagen = this.imagen;
        }
        var that = this;
        if (this.id != null) {
          this.$axios
            .put(this.$serverURL + "/api/eventos/" + this.id + "/", json)
            .then(function (response) {
              console.log(response);
              that.guardando = false;
              that.$emit("get");
              that.cerrar();
            })
            .catch(function (response) {
              console.log(response);
              that.guardando = false;
              that.$emit("get");
              Object.values(response.response.data).forEach((d) =>
                that.$emitter.emit("toast-error", d.length == 1 ? d[0] : d)
              );
            });
        } else {
          console.log(json);
          this.$axios
            .post(this.$serverURL + "/api/eventos/", json)
            .then(function (response) {
              console.log(response);
              that.guardando = false;
              that.$emit("get");
              that.cerrar();
            })
            .catch(function (response) {
              console.log(response);
              that.guardando = false;
              that.$emit("get");
              Object.values(response.response.data).forEach((d) =>
                that.$emitter.emit("toast-error", d.length == 1 ? d[0] : d)
              );
            });
        }
      } else {
        this.guardando = false;
      }
    },
    cerrar() {
      this.$emit("cerrar");
      this.titulo = "Crear nuevo evento";
      this.limpiar();
      this.seccion = 1;
      this.mostrarSalir = false;
    },
    limpiar() {
      this.id = null;
      this.tipo = "";
      this.tituloEvento = "";
      this.horario = null;
      this.descripcion = "";
      this.vigilancia = null;
      this.imagenNombre = "";
      this.direccionAlterna = "";
      this.direccionAlternaCheck = false;
      this.$refs.timepicker.clearTime();
      this.$refs.dropdownVigilancia.selectedOption = null;
      this.$refs.dropdownVigilancia.$refs.dropdownInput2.value = "";
      this.$refs.dropdownVigilancia.placeholderText = "Seleccione vigilancia";
      this.$refs.dropdownTipo.selectedOption = null;
      this.$refs.dropdownTipo.$refs.dropdownInput2.value = "";
      this.$refs.dropdownTipo.placeholderText = "Seleccione tipo";
    },
    validateEmail(email) {
      if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        return true;
      }
      return false;
    },
    elegirImagen() {
      this.$refs.imagenUpload.click();
    },
    changeImagen() {
      var that = this;
      var reader = new FileReader();

      reader.onload = function () {
        that.reduceImageSize();
      };
      reader.readAsDataURL(this.$refs.imagenUpload.files[0]);
    },
    reduceImageSize() {
      // Read in file
      var file = this.$refs.imagenUpload.files[0];
      this.imagenNombre = file.name;
      var that = this;
      // Ensure it's an image
      if (file.type.match(/image.*/)) {
        console.log("An image has been loaded");

        // Load the image
        var reader = new FileReader();
        reader.onload = function (readerEvent) {
          var image = new Image();
          image.onload = function () {
            // Resize the image
            var canvas = document.createElement("canvas"),
              max_size = 800, // TODO : pull max size from a site config
              width = image.width,
              height = image.height;
            if (width > height) {
              if (width > max_size) {
                height *= max_size / width;
                width = max_size;
              }
            } else {
              if (height > max_size) {
                width *= max_size / height;
                height = max_size;
              }
            }
            canvas.width = width;
            canvas.height = height;
            canvas.getContext("2d").drawImage(image, 0, 0, width, height);
            var dataUrl = canvas.toDataURL("image/jpeg");
            that.imagen = dataUrl;
          };
          image.src = readerEvent.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    changeTime(event) {
      this.horario = {
        HH: event.data.HH,
        mm: event.data.mm,
      };
    },
    getRangoHoraHasta() {
      const array = [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
        20, 21, 22, 23,
      ];
      return array.filter((h) => h <= new Date().getHours());
    },
  },
};
</script>

<style scoped lang="scss" src="@/assets/css/views/secciones.scss">
</style>
