<template>
  <div class="popup-container">
    <div class="popup-mask"></div>
    <div class="popup">
      <div class="popup-top">
        <label>Evento</label>
        <img src="../../assets/img/icon/close_popup.png" @click="cerrar()" />
        <div class="popup-top-btn-container">
          <div class="popup-top-btn" @click="mostrarEliminar = true">
            <img src="../../assets/img/popup/delete.png" />
          </div>
          <div class="popup-top-btn" v-if="esReportePropio()" @click="editar()">
            <img src="../../assets/img/popup/edit.png" />
          </div>
        </div>
      </div>
      <div class="popup-body popup-body-scroll">
        <div class="popup-row">
          <label class="popup-label-info">Emisor</label>
          <label>{{
            evento.emisor != null
              ? evento.emisor.nombre + " " + evento.emisor.apellido
              : "-"
          }}</label>
        </div>
        <div class="popup-row-double">
          <div class="popup-row">
            <label class="popup-label-info">Tipo de evento</label>
            <label class="popup-label-capitalize">{{ evento.tipo }}</label>
          </div>
          <div class="popup-row">
            <label class="popup-label-info">Horario de suceso</label>
            <label class="popup-data">{{
              evento.horario_suceso.split("T")[1].substr(0, 5)
            }}</label>
          </div>
        </div>
        <div class="popup-row">
          <label class="popup-label-info">Título de evento</label>
          <label>{{ evento.titulo }}</label>
        </div>
        <div class="popup-row">
          <label class="popup-label-info">Descripción</label>
          <label>{{ evento.descripcion }}</label>
        </div>
        <div class="popup-row">
          <label class="popup-label-info">Ubicación</label>
          <label>{{
            evento.gps != null
              ? evento.gps
              : evento.ubicacion_alternativa != null &&
                evento.ubicacion_alternativa != ""
              ? evento.ubicacion_alternativa
              : "-"
          }}</label>
        </div>
        <div class="popup-row">
          <label class="popup-label-info">Imagen</label>
          <label v-if="evento.imagen == null">-</label>
          <img
            class="popup-imagen"
            v-if="evento.imagen != null"
            :src="$serverURL + evento.imagen"
            @click="mostrarImagen = true"
          />
        </div>
      </div>
    </div>
    <Eliminar
      :mostrar="mostrarEliminar"
      :tipo="'Evento'"
      :nombre="evento.nombre"
      @cerrar="eliminarCerrar()"
      @aceptar="eliminarAceptar()"
    ></Eliminar>
    <div
      v-if="mostrarImagen"
      class="popup-imagen-container"
      style="background-color: rgb(0 0 0 / 60%)"
    >
      <img
        class="popup-imagen-container-close"
        src="../../assets/img/icon/close_white.png"
        @click="mostrarImagen = false"
      />
      <img
        class="popup-imagen-container-img"
        v-if="evento.imagen != null"
        :src="$serverURL + evento.imagen"
      />
    </div>
  </div>
</template>

<script>
import Eliminar from "@/components/Eliminar.vue";
import { mapGetters } from "vuex";

export default {
  name: "Info_Evento",
  components: { Eliminar },
  props: {
    evento: Object,
  },
  data() {
    return {
      mostrarEliminar: false,
      mostrarImagen: false,
    };
  },
  computed: {
    ...mapGetters(["usuario"]),
  },
  mounted() {},
  methods: {
    editar() {
      this.$emit("editar", this.evento);
      this.cerrar();
    },
    cerrar() {
      this.$emit("cerrar");
    },
    eliminarCerrar() {
      this.mostrarEliminar = false;
    },
    eliminarAceptar() {
      this.mostrarEliminar = false;
      var that = this;
      this.$axios
        .delete(this.$serverURL + "/api/eventos/" + this.evento.id + "/")
        .then(function (response) {
          console.log(response);
          that.$emit("get");
          that.cerrar();
        })
        .catch(function (response) {
          console.log(response);
        });
    },
    esReportePropio() {
      return this.evento != null && this.usuario.id == this.evento.emisor.id;
    },
  },
};
</script>

<style scoped lang="scss" src="@/assets/css/views/secciones.scss"></style>
<style scoped lang="scss" src="@/assets/css/views/eventos.scss"></style>
